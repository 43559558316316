import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ToolPreview from '../components/tool-preview'

class ToolIndex extends React.Component {
  render() {
    const posts = [
      {
        slug: 'loto7',
        title: 'Loto7',
        description: '番号生成補助ツール',
        tags: ['宝くじ'],
      },
      {
        slug: 'loto7',
        title: 'Loto7',
        description: '番号生成補助ツール',
        tags: ['宝くじ'],
      },
      {
        slug: 'loto7',
        title: 'Loto7',
        description: '番号生成補助ツール',
        tags: ['宝くじ'],
      },
    ]
    return (
      <Layout location={this.props.location}>
        <Seo title="Tool" />
        <Hero title="Tool" />
        <ToolPreview posts={posts} />
      </Layout>
    )
  }
}

export default ToolIndex
