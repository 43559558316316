import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Container from './container'
import Tags from './tags'
import * as styles from './tool-preview.module.css'

const ToolPreview = ({ posts }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <Container>
      <ul className={styles.articleList}>
        {posts.map((post) => {
          return (
            <li key={post.slug}>
              <Link to={`/tool/${post.slug}`} className={styles.link}>
                <StaticImage
                  alt=""
                  src="tool-preview.jpg"
                  width={424}
                  height={212}
                />
                <h2 className={styles.title}>{post.title}</h2>
              </Link>
              <div>{post.description}</div>
              <div className={styles.meta}>
                <small className="meta">{post.publishDate}</small>
                <Tags tags={post.tags} />
              </div>
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default ToolPreview
